
import UserService from '../../services/user'

const mutations = {
  GET_ME_REQUEST(state) {
    state.isGettingMe = true
  },
  GET_ME_SUCCESS(state, payload) {
    state.isGettingMe = false
    state.isGettingMeError = true
    state.userInfo = payload
    const { accountType = '', permissions = [] } = payload;
    // accountType = 1 -> Owner
    state.userPermissions = `${accountType}` === '1' ? ['1'] : permissions.map((permission) => `${permission}`) || []
  },
  GET_ME_FAILURE(state) {
    state.isGettingMe = false
    state.isGettingMeError = true
  },
}
const actions = {
  async getMe({ commit, dispatch }) {
    try {
      commit('GET_ME_REQUEST')
      const response = await UserService.getMe()
      commit('GET_ME_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ME_FAILURE')
    }
  },
}

const getters = {
  userInfo: (state) => state.userInfo,
  userPermissions: (state) => state.userPermissions,
}
const state = {
  userInfo: {
    billingEmail: '',
    contactEmail: '',
    contactName: '',
    domain: '',
    email: 'email@hostname.com',
    id: '',
    name: '',
  },
  isGettingMe: false,
  isGettingMeError: false,
  /** 
   * 1 = Superuser
   * 2 = Billing
   * 3 = IntegrationRequest
   * 4 = Agent
   * 5 = Game
   * 6 = HistoryLog
   * 7 = Bot
   * 8 = Setting
   */
  userPermissions: [],
}
export default {
  state,
  mutations,
  actions,
  getters,
}
