/* cSpell:disable */
export default {
  routes: {
    profile: 'ข้อมูลโปรไฟล์',
    main_menu: 'เมนูหลัก',
    dashboard: 'แดชบอร์ด',
    bills: 'การเก็บเงิน',
    billings: 'เก็บเงินตัวแทน',
    manage_agents: 'จัดการตัวแทน',
    agents_requests: 'คำขอเชื่อมต่อระบบ',
    agents_list: 'รายชื่อตัวแทน',
    agents_register: 'สมัครตัวแทน',
    agents_detail: 'ข้อมูลตัวแทน',
    games: 'ตั้งค่าเกม & ประวัติการเล่น',
    game_list: 'รายการเกม',
    game_history: 'ประวัติการเล่นเกม',
    settings: 'การตั้งค่า & อื่น ๆ',
    assistants: 'จัดการผู้ช่วย',
    account: 'ตั้งค่าข้อมูลส่วนตัว',
    activity: 'บันทึกกิจกรรม',
    bot_reward: 'บอท และการรับรางวัล',
    assistant_create: 'สร้างผู้ช่วย',
    assistant_list: 'จัดการผู้ช่วย',
    assistant_detail: 'ข้อมูลผู้ช่วย',
  },
  pages: {
    login: {
      forgot_password: 'ลืมรหัสผ่าน?',
    },
    forget_password: {
      title: 'ลืมรหัสผ่าน',
      subtitle: 'เราจะส่งรหัส OTP ไปที่โทรศัพท์หมายเลข (xxx xxxx xx78)',
      otp_code: 'รหัสยืนยัน',
      resend: 'ส่ง OTP อีกครั้ง',
      get_otp: 'กดรับ OTP ตอนนี้',
      verify: 'ตรวจสอบและดำเนินการต่อ',
      back_login: 'กลับไปหน้า ล๊อคอิน',
    },
    dashboard: {
      overview: 'ภาพรวม',
      today: 'วันนี้',
      total_players: 'สมาชิกทั้งหมด',
      total_free_credit: 'รับเครดิตฟรีทั้งหมด',
      total_loss_free_credit: 'จำนวนเครดิตฟรีที่เสียทั้งหมด',
      total_deposit: 'ยอดฝากรวมทั้งหมด',
      total_withdraw: 'ยอดถอนรวมทั้งหมด',
      total_revenue: 'สรุปรายได้ทั้งหมด',
      today_new_register: 'สมาชิกที่สมัครวันนี้',
      today_free_credit: 'รับเครดิตฟรีวันนี้',
      today_loss_free_credit: 'จำนวนเครดิตฟรีที่เสียวันนี้',
      today_deposit: 'ยอดฝากรวมวันนี้',
      today_withdraw: 'ยอดถอนรวมวันนี้',
      today_revenue: 'สรุปรายได้วันนี้',
      total_first_deposit: 'เติมเงินครั้งแรกทั้งหมด',
      total_first_daily_deposit: 'ฝากครั้งแรกของวันทั้งหมด',
      total_all_deposit_bonus: 'รับโบนัสทุกยอดฝากทั้งหมด',
      total_schedule_deposit: 'ฝากเป็นเวลาทั้งหมด',
      total_accumulated_deposit: 'เติมสะสมทั้งหมด',
      today_first_deposit: 'เติมเงินครั้งแรกวันนี้',
      today_first_daily_deposit: 'ฝากครั้งแรกของวันวันนี้',
      today_all_deposit_bonus: 'รับโบนัสทุกยอดฝากวันนี้',
      today_schedule_deposit: 'ฝากเป็นเวลาวันนี้',
      today_accumulated_deposit: 'เติมสะสมวันนี้',
      stats: {
        total_credits: 'เครดิตทั้งหมด',
        total_agents: 'จำนวนเอเยนต์',
        total_players: 'จำนวนผู้เล่น',
        player_overview: 'ภาพรวมผู้เล่น',
        agent_overview: 'ภาพรวมเอเยนต์',
        new_players: 'ผู้เล่นใหม่',
        all_players: 'ผู้เล่นทั้งหมด',
        total_top_up: 'เติมเครดิตรวม',
      },
    },
    mini_games: {
      title: 'มินิเกม',
      reports: 'ผลมินิเกม',
      total_games: 'จำนวนการเล่นทั้งหมด',
      total_bet: 'จำนวนเดิมพันรวม',
      total_fee: 'ผลกินน้ำรวม',
    },
    provider_games: {
      title: 'เกมค่ายต่างๆ',
    },
  },
  utils: {
    lang: {
      th: 'ภาษาไทย',
      en: 'ภาษาอังกฤษ',
    },
    periods: {
      day: 'รายวัน',
      week: 'ต่อสัปดาห์',
      month: 'ต่อเดือน',
    },
  },
  buttons: {
    login: 'เข้าสู่ระบบ',
    logout: 'ออกจากระบบ',
    signup: 'สมัครสมาชิก',
    reset_password: 'ตั้งรหัสผ่านใหม่',
    submit: 'ตกลง',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    copy: 'คัดลอก',
    copied: 'คัดลอกแล้ว!',
    add: 'เพิ่มใหม่',
    remove: 'ลบทิ้ง',
    edit: 'แก้ไข',
    add_bank_account: 'เพิ่มบัญชี',
    back: 'ย้อนกลับ',
    access_login: 'เข้าใช้งาน',
    operation: 'ทำรายการ',
    clear: 'ล้างข้อมูล',
    view: 'ดูรายละเอียด',
    change_status: 'เปลี่ยนสถานะ',
  },
  messages: {
    enter_details: 'ใส่ข้อมูลของคุณด้านล่าง',
    nothing_here: 'ไม่มีข้อมูล',
    password_not_match: 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
    none: 'ไม่มีข้อความ',
    complete_form: 'กรอกข้อมูลด้านล่างให้ครบถ้วน',
    invalid_form: 'กรอกข้อมูลไม่ครบถ้วน',
    example: 'ดูตัวอย่าง',

    404: '404 - ไม่พบ URL คำขอบนเซิร์ฟเวอร์นี้',
    server_error: '{value} - ระบบภายในขัดข้อง',
    success: 'คำขอของคุณเสร็จสมบูรณ์แล้ว',
    error: '{code} - เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
    network_error: 'กรุณาตรวจสอบเครือข่ายอินเตอร์เน็ตของคุณ',
    try_again: 'เกิดปัญหาขึ้นจากระบบภายใน กรุณาลองอีกครั้งภายหลัง...',
    confirm: 'คุณแน่ใจหรือไม่ว่าจะดำเนินการต่อ?',
  },
  user: {
    prefix: 'รหัสสมาชิก',
    username: 'เบอร์ยูสเซอร์',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    password: 'รหัสผ่าน',
    new_password: 'รหัสผ่านใหม่',
    old_password: 'รหัสผ่านเก่า',
    confirm_password: 'ยืนยันรหัสผ่าน',
    email: 'อีเมล์',
    registered_date: 'วันที่สมัคร',
    referral: 'ผู้แนะนำ',
    link_af: 'Link AF',
    dateOfBirth: 'วันเกิด',
    phone: 'เบอร์โทรศัพท์',
    ban_user: 'แบนผู้เล่น',
    cancel_ban_user: 'ยกเลิกการแบนผู้เล่น',
    bet_report: 'ยอดแทง',
    today_bet: 'ยอดแทงวันนี้',
    bet_history: 'ยอดแทงย้อนหลัง',
    withdraw_revenue_history: 'ประวัติการรับ/ถอนรายได้',
    has_line_signin: 'ล็อกอินด้วย Line',
    note: 'โน๊ต',
  },
}
