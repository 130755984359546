/* cSpell:disable */
export default {
  routes: {
    profile: 'My account',
    main_menu: 'เมนูหลัก',
    dashboard: 'แดชบอร์ด',
    bills: 'การเก็บเงิน',
    billings: 'เก็บเงินตัวแทน',
    manage_agents: 'จัดการตัวแทน',
    agents_requests: 'คำขอเชื่อมต่อระบบ',
    agents_list: 'รายชื่อตัวแทน',
    agents_register: 'สมัครตัวแทน',
    agents_detail: 'ข้อมูลตัวแทน',
    games: 'ตั้งค่าเกม & ประวัติการเล่น',
    game_list: 'รายการเกม',
    game_history: 'ประวัติการเล่นเกม',
    settings: 'การตั้งค่า & อื่น ๆ',
    account: 'ตั้งค่าข้อมูลส่วนตัว',
    activity: 'บันทึกกิจกรรม',
    bot_reward: 'บอท และการรับรางวัล',
    assistants: 'จัดการผู้ช่วย',
    assistant_create: 'สร้างผู้ช่วย',
    assistant_list: 'จัดการผู้ช่วย',
    assistant_detail: 'ข้อมูลผู้ช่วย',
  },
  pages: {
    login: {
      forgot_password: 'Forgot your password?',
    },
    forget_password: {
      title: 'Forget password',
      subtitle: 'We will send you a OTP on your phone number (xxx xxxx xx78)',
      otp_code: 'Verification code',
      resend: 'Resend OTP',
      get_otp: 'Get OTP now',
      verify: 'Verify & Proceed',
      back_login: 'Back to Login',
    },
  },
  utils: {
    lang: {
      th: 'Thai',
      en: 'English',
    },
    periods: {
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
    },
  },
  buttons: {
    login: 'Login',
    logout: 'Logout',
    signup: 'Sign up',
    reset_password: 'Reset password',
    save: 'Save',
    submit: 'Submit',
    cancel: 'Cancel',
    confirm: 'Confirm',
    copy: 'Copy',
    copied: 'Copied!',
    add: 'Add new',
    remove: 'Remove',
    edit: 'Edit',
    back: 'Back',
    access_login: 'Access login',
    operation: 'Operation',
    clear: 'Clear data',
    view: 'View',
    change_status: 'Change Status',
  },
  messages: {
    enter_details: 'Enter your details below.',
    nothing_here: 'Nothing here.',
    password_not_match: 'Password and confirm password do not match.',
    none: 'No message',
    complete_form: 'Complete the information below.',
    invalid_form: 'Missing required fields.',
    example: 'See example',

    success: 'Your request has been completed successfully.',
    error: '{code} - Something bad happened, please try again.',
    404: '404 - The request URL was not found on this server.',
    server_error: '{value} - Internal server error',
    network_error: 'Please check your network connection.',
    try_again: 'Something went wrong, please try again later...',
    confirm: 'Are you sure to continue this?',
  },
  user: {
    prefix: 'Prefix',
    username: 'Username',
    firstname: 'First name',
    lastname: 'Last name',
    password: 'Password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    email: 'Email',
    registered_date: 'Registered date',
    referral: 'Referral friend',
    link_af: 'Link AF',
    dateOfBirth: 'Date of birth',
    phone: 'Phone number',
    ban_user: 'Ban user',
    cancel_ban_user: 'Cancel ban user',
    bet_report: 'Bet report',
    today_bet: 'Today/s bet',
    bet_history: 'Bet history',
    withdraw_revenue_history: 'Withdraw revenue history',
    has_line_signin: 'Has Line signin',
    note: 'Note',
  },
}
