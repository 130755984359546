import GameService from '../../services/game'
import router from '../../router/index'

const mutations = {
  GET_GAME_HISTORY_REQUEST(state) {
    state.isGettingGameHistory = true
  },
  GET_GAME_HISTORY_SUCCESS(state, payload) {
    state.isGettingGameHistory = false
    state.gameHistory = payload
  },
  GET_GAME_HISTORY_FAILURE(state) {
    state.isGettingGameHistory = false
  },

  GET_GAME_HISTORY_DETAIL_REQUEST(state) {
    state.isGettingGameHistoryDetail = true
  },
  GET_GAME_HISTORY_DETAIL_SUCCESS(state, payload) {
    state.isGettingGameHistoryDetail = false
    state.gameHistoryDetail = payload
  },
  GET_GAME_HISTORY_DETAIL_FAILURE(state) {
    state.isGettingGameHistoryDetail = false
  },

  GET_GAME_LIST_REQUEST(state) {
    state.isGettingGameList = true
  },
  GET_GAME_LIST_SUCCESS(state, payload) {
    state.isGettingGameList = false
    state.games = payload
  },
  GET_GAME_LIST_FAILURE(state) {
    state.isGettingGameList = false
  },
  
  GET_GAME_DETAIL_REQUEST(state) {
    state.isGettingGameDetail = true
  },
  GET_GAME_DETAIL_SUCCESS(state, payload) {
    state.isGettingGameDetail = false
    state.gameDetail = payload
  },
  GET_GAME_DETAIL_FAILURE(state) {
    state.isGettingGameDetail = false
  },

  UPDATE_GAME_REQUEST(state) {
    state.isUpdatingGame = true
  },
  UPDATE_GAME_SUCCESS(state) {
    state.isUpdatingGame = false
  },
  UPDATE_GAME_FAILURE(state) {
    state.isUpdatingGame = false
  },

  GET_BLACKLIST_REQUEST(state) {
    state.isGettingBlacklist = true
  },
  GET_BLACKLIST_SUCCESS(state, payload) {
    state.isGettingBlacklist = false
    state.blacklist = payload
  },
  GET_BLACKLIST_FAILURE(state) {
    state.isGettingBlacklist = false
  },

  ADD_BLACKLIST_REQUEST(state) {
    state.isAddingBlacklist = true
    state.isAddBlacklistSuccess = false
  },
  ADD_BLACKLIST_SUCCESS(state) {
    state.isAddingBlacklist = false
    state.isAddBlacklistSuccess = true
  },
  ADD_BLACKLIST_FAILURE(state) {
    state.isAddingBlacklist = false
  },

  REMOVE_BLACKLIST_REQUEST(state) {
    state.isRemovingBlacklist = true
  },
  REMOVE_BLACKLIST_SUCCESS(state) {
    state.isRemovingBlacklist = false
  },
  REMOVE_BLACKLIST_FAILURE(state) {
    state.isRemovingBlacklist = false
  },
}

const actions = {
  async getGameHistory({ commit, dispatch }) {
    try {
      commit('GET_GAME_HISTORY_REQUEST')
      const { data } = await GameService.getHistory()
      commit('GET_GAME_HISTORY_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_HISTORY_FAILURE')
    }
  },
  async getGameHistoryDetail({ commit, dispatch }, id) {
    try {
      commit('GET_GAME_HISTORY_DETAIL_REQUEST')
      const data = await GameService.getHistoryDetail(id)
      commit('GET_GAME_HISTORY_DETAIL_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_HISTORY_DETAIL_FAILURE')
    }
  },

  async getGameList({ commit, dispatch }) {
    try {
      commit('GET_GAME_LIST_REQUEST')
      const { data } = await GameService.getGameList()
      commit('GET_GAME_LIST_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_LIST_FAILURE')
    }
  },
  async getGameDetail({ commit, dispatch }, id) {
    try {
      commit('GET_GAME_DETAIL_REQUEST')
      const data = await GameService.getGameDetail(id)
      commit('GET_GAME_DETAIL_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_DETAIL_FAILURE')
    }
  },

  async updateGameSetting({ commit, dispatch }, {id, data}) {
    try {
      commit('UPDATE_GAME_REQUEST')
      await GameService.updateGameSetting(id, data)      
      commit('UPDATE_GAME_SUCCESS')
      dispatch('app/notifySuccess', 'บันทึกการแก้ไขเกมสำเร็จ')
      router.push({ name: 'game_list' })
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('UPDATE_GAME_FAILURE')
    }
  },

  async getBlacklist({ commit, dispatch }, gameId) {
    try {
      commit('GET_BLACKLIST_REQUEST')
      const response = await GameService.getBlacklist(gameId)
      commit('GET_BLACKLIST_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_BLACKLIST_FAILURE')
    }
  },

  async addBlacklist({ commit, dispatch }, {id, data}) {
    try {
      commit('ADD_BLACKLIST_REQUEST')
      await GameService.addBlacklist(id, data)
      commit('ADD_BLACKLIST_SUCCESS')

      dispatch('app/notifySuccess', 'เพิ่ม blacklist สำเร็จ')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('ADD_BLACKLIST_FAILURE')
    }
  },

  async removeBlacklist({ commit, dispatch }, id) {
    try {
      commit('REMOVE_BLACKLIST_REQUEST')
      await GameService.removeBlacklist(id)
      commit('REMOVE_BLACKLIST_SUCCESS')

      dispatch('app/notifySuccess', 'ลบ blacklist สำเร็จ')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REMOVE_BLACKLIST_FAILURE')
    }
  },
}

const getters = {
  gameHistory: (state) => state.gameHistory,
  gameHistoryDetail: (state) => state.gameHistoryDetail,
  games: (state) => state.games,
  gameDetail: (state) => state.gameDetail,

  blacklist: (state) => state.blacklist,
}

const state = {
  isGettingGameHistory: false,
  gameHistory: [],

  isGettingGameHistoryDetail: false,
  gameHistoryDetail: {},


  isGettingGameList: false,
  games: [],

  isGettingGameDetail: false,
  gameDetail: {},

  isUpdatingGame: false,

  isGettingBlacklist: [],
  blacklist: [],

  isAddingBlacklist: false,
  isAddBlacklistSuccess: false,
  isRemovingBlacklist: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
