<template>
  <flat-pickr
    v-model="value"
    :config="dateConfig"
    class="form-control"
    placeholder="วัน-เวลาที่"
    style="background-color: #ffffff !important"
  />
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
      dateConfig: {
        enableTime: true,
        time_24hr: true
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
  created() {
    if (this.selectedDate) {
      this.value = this.selectedDate
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
