const globalFiles = require.context('./', false, /\.js$/)

const modules = {}

const extractModuleName = (files) => {
  files.keys().forEach((fileName) => {
    if (fileName === './index.js') {
      return
    }
    const normalizeFileName = fileName.replace('./', '').replace('.js', '')
    modules[normalizeFileName] = files(fileName).default
  })
}

extractModuleName(globalFiles)

export {
  modules
}
