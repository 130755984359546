import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/accounts`

export default {
  getPermissions() {
    try {
      return axios.get(`${baseURL}/permissions`)
    } catch (e) {
      throw e
    }
  },
  getAssistants() {
    try {
      return axios.get(`${baseURL}/type/4`)
    } catch (e) {
      throw e
    }
  },
  createAssistant(data) {
    try {
      return axios.post(`${baseURL}/assistants`, data)
    } catch (e) {
      throw e
    }
  },
  getAssistantById(id) {
    try {
      return axios.get(`${baseURL}/assistants/${id}`)
    } catch (e) {
      throw e
    }
  },
  updateAssistant(data) {
    try {
      return axios.put(`${baseURL}/assistants`, data)
    } catch (e) {
      throw e
    }
  },
  removeAssistant(assistantId) {
    try {
      return axios.delete(`${baseURL}/assistants/${assistantId}`)
    } catch (e) {
      throw e
    }
  },
}
