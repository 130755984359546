export default [
  {
    key: 'assistantCreate:0',
    path: '/assistant/create',
    name: 'assistant_create',
    route: 'assistant_create',
    icon: 'UserPlusIcon',
    component: () => import('@/views/assistants/AssistantDetail.vue'),
    meta: {
      authRequired: true,
      isAppMenu: false,
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          name: 'assistant_list',
          to: '/assistants',
        },
        {
          name: 'assistant_create',
          active: true,
        },
      ],
    },
  },

  {
    key: 'assistantDetail:0',
    path: '/assistant/detail/:id',
    name: 'assistant_detail',
    route: 'assistant_detail',
    icon: 'UserPlusIcon',
    component: () => import('@/views/assistants/AssistantDetail.vue'),
    meta: {
      authRequired: true,
      isAppMenu: false,
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          name: 'assistant_list',
          to: '/assistants',
        },
        {
          name: 'assistant_detail',
          active: true,
        },
      ],
    },
  },
]
