import AgentService from '../../services/agents'
import router from '../../router/index'

const mutations = {
  GET_AGENTS_REQUEST(state) {
    state.isGettingAgents = true
  },
  GET_AGENTS_SUCCESS(state, payload) {
    state.isGettingAgents = false
    state.agents = payload
  },
  GET_AGENTS_FAILURE(state) {
    state.isGettingAgents = false
  },
  GET_REGISTRATION_LINK_REQUEST(state) {
    state.isGettingRegistrationLink = true
  },
  GET_REGISTRATION_LINK_SUCCESS(state, payload) {
    state.isGettingRegistrationLink = false
    state.registrationLink = payload
  },
  GET_REGISTRATION_LINK_FAILURE(state) {
    state.isGettingRegistrationLink = false
  },
  ACTIVATE_CREDENTIAL_REQUEST(state) {
    state.isActivatingCredential = true
    state.isActivateCredentialSuccess = false
  },
  ACTIVATE_CREDENTIAL_SUCCESS(state) {
    state.isActivatingCredential = false
    state.isActivateCredentialSuccess = true
  },
  ACTIVATE_CREDENTIAL_FAILURE(state) {
    state.isActivatingCredential = false
    state.isActivateCredentialSuccess = false
  },
  SUSPEND_CREDENTIAL_REQUEST(state) {
    state.isSuspendingCredential = true
    state.isSuspendCredentialSuccess = false
  },
  SUSPEND_CREDENTIAL_SUCCESS(state) {
    state.isSuspendingCredential = false
    state.isSuspendCredentialSuccess = true
  },
  SUSPEND_CREDENTIAL_FAILURE(state) {
    state.isSuspendingCredential = false
    state.isSuspendCredentialSuccess = false
  },
  EDIT_AGENT_REQUEST(state) {
    state.isEditingAgent = true
    state.isEditAgentSuccess = false
  },
  EDIT_AGENT_SUCCESS(state) {
    state.isEditingAgent = false
    state.isEditAgentSuccess = true
  },
  EDIT_AGENT_FAILURE(state) {
    state.isEditingAgent = false
    state.isEditAgentSuccess = false
  },
  REMOVE_AGENT_REQUEST(state) {
    state.isRemovingAgent = true
    state.isRemoveAgentSuccess = false
  },
  REMOVE_AGENT_SUCCESS(state) {
    state.isRemovingAgent = false
    state.isRemoveAgentSuccess = true
  },
  REMOVE_AGENT_FAILURE(state) {
    state.isRemovingAgent = false
    state.isRemoveAgentSuccess = false
  },
}
const actions = {
  async getAgents({ commit, dispatch }) {
    try {
      commit('GET_AGENTS_REQUEST')
      const { data = [] } = await AgentService.getAccounts()
      commit('GET_AGENTS_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_AGENTS_FAILURE')
    }
  },
  async getRegistrationLink({ commit, dispatch }) {
    try {
      commit('GET_REGISTRATION_LINK_REQUEST')
      const response = await AgentService.registerLink()
      commit(
        'GET_REGISTRATION_LINK_SUCCESS',
        `${
          process.env.VUE_APP_CUSTOMER_URL
        }/register/?token=${response?.token || ''}`
      )
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_REGISTRATION_LINK_FAILURE')
    }
  },
  async activateCredential({ commit, dispatch }, data) {
    try {
      commit('ACTIVATE_CREDENTIAL_REQUEST')
      const response = await AgentService.activateCredential(data)
      commit('ACTIVATE_CREDENTIAL_SUCCESS', response)
      dispatch('app/notifySuccess')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('ACTIVATE_CREDENTIAL_FAILURE')
    }
  },
  async suspendCredential({ commit, dispatch }, data) {
    try {
      commit('SUSPEND_CREDENTIAL_REQUEST')
      const response = await AgentService.suspendCredential(data)
      commit('SUSPEND_CREDENTIAL_SUCCESS', response)
      dispatch('app/notifySuccess')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('SUSPEND_CREDENTIAL_FAILURE')
    }
  },
  async editAgent({ commit, dispatch }, data) {
    try {
      commit('EDIT_AGENT_REQUEST')
      const response = await AgentService.editAgent(data)
      dispatch('app/notifySuccess', 'แก้ไขข้อมูลสำเร็จ')

      commit('EDIT_AGENT_SUCCESS', response)
      dispatch('app/notifySuccess')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('EDIT_AGENT_FAILURE')
    }
  },

  async removeAgent({ commit, dispatch }, data) {
    try {
      commit('REMOVE_AGENT_REQUEST')
      const response = await AgentService.removeAgent(data)
      commit('REMOVE_AGENT_SUCCESS', response)
      dispatch('app/notifySuccess')
      if (router.currentRoute.name !== 'requests') {
        router.push({ name: 'requests' })
      }
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REMOVE_AGENT_FAILURE')
    }
  },
}

const getters = {
  agents: (state) => state.agents,
  registrationLink: (state) => state.registrationLink,
}
const state = {
  isGettingAgents: false,
  agents: [],

  isGettingRegistrationLink: false,
  registrationLink: '',

  isActivatingCredential: false,
  isActivateCredentialSuccess: false,
  isSuspendingCredential: false,
  isSuspendCredentialSuccess: false,

  isEditingAgent: false,
  isEditAgentSuccess: false,

  isRemovingAgent: false,
  isRemoveAgentSuccess: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
