import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}`

export default {
  getBills(search, skip, limit) {
    try {
      return axios.get(`${baseURL}/bills?search=${search}&skip=${skip}&limit=${limit}`)
    } catch (e) {
      throw e
    }
  },
  getInvoices(search, skip, limit, status) {
    try {
      return axios.get(`${baseURL}/invoices?search=${search}&skip=${skip}&limit=${limit}&status=${status}`)
    } catch (e) {
      throw e
    }
  },
  addInvoice(data) {
    try {
      return axios.post(`${baseURL}/invoices`, data)
    } catch (e) {
      throw e
    }
  },
  getPayments(search, skip, limit) {
    try {
      return axios.get(`${baseURL}/payments?search=${search}&skip=${skip}&limit=${limit}&status=${status}`)
    } catch (e) {
      throw e
    }
  },
  getPaymentById(id) {
    try {
      return axios.get(`${baseURL}/payments/${id}`)
    } catch (e) {
      throw e
    }
  },
  getPaymentSlip(id) {
    try {
      return axios.get(`${baseURL}/payments/${id}/slip`)
    } catch (e) {
      throw e
    }
  },
  makePayment(data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(`${baseURL}/payments`, formData, options)
    } catch (e) {
      throw e
    }
  },
  removePayment(id) {
    try {
      return axios.delete(`${baseURL}/payments/${id}`)
    } catch (e) {
      throw e
    }
  },
}
