import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn } from '../services/user'
import bills from './routes/bills'
import agents from './routes/agents'
import games from './routes/games'
import settings from './routes/settings'
import pages from './routes/pages'
import assistants from './routes/assistants'

import user from './routes/user'

const appRoutes = [
  ...bills,
  ...agents,
  ...games,
  ...assistants,
  ...settings,
  ...user,
]

const routeFilter = (routes) =>
  routes
    .map((route) =>
      route.children
        ? { ...route, children: routeFilter(route.children) }
        : route
    )
    .filter((route) => route.meta.isAppMenu)

export const appMenuItems = routeFilter(appRoutes)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...pages,
    ...appRoutes,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // Redirect to login if not logged in
  const authRequired = to.matched.some((route) => route.meta.authRequired)
  if (authRequired) {
    return isLoggedIn ? next() : next('/login')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('dashboard')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
