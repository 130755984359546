import Vue from 'vue'
import { mapGetters } from 'vuex'
import { banks } from './constants'

Vue.mixin({
  data() {
    return {
      bankList: banks,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    $windowWidth() {
      return this.windowWidth
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    $displayStatusColor(status) {
      const val = status ? status.toLowerCase() : ''
      if (val === 'active') {
        return 'success'
      }
      if (val === 'blocked') {
        return 'danger'
      }
      if (val === 'inactive') {
        return 'dark'
      }
      return ''
    },
    $displayBankName(bankCode) {
      const found = this.bankList.find((item) => item.bank_code === bankCode)
      return found ? found.bank_name_th : bankCode
    },
    $addPageToLocation(page) {
      history.pushState({}, null, `${this.$route.path}?page=${page}`)
    },
    $addPageQuery(queryKey, queryValue) {
      history.pushState({}, null, `${this.$route.path}?${queryKey}=${queryValue}`)
    },
    $navigateQueryToLocation(route, { query }) {
      const { key, value } = query
      this.$router.push(`${route}?${key}=${value}`)
    },
    $displayEnvironment(value) {
      const environment = +value
      if (environment === 0) {
        return 'Sandbox'
      } else if (environment === 1) {
        return 'Production'
      }
      return environment
    },
    $displayAccountType(value) {
      const accountType = +value
      if (accountType === 1) {
        return 'Owner'
      } else if (accountType === 2) {
        return 'Provider'
      } else if (accountType === 3) {
        return 'Operator'
      }
      return 'ไม่มีข้อมูล'
    },
    $displayHistoryType(type) {
      // * 0 - Win
      // * 1 - Lose
      // * 2 - Bot Win
      // * 3 - Draw
      // * 4 - Bet
      // * 5 - Cancel Bet
      // * 6 - Void
      // * 7 - Unvoid 
       switch (+type) {
        case 0:
          return 'ชนะ'
        case 1:
          return 'แพ้'
        case 2:
          return 'บอทชนะ'
        case 3:
          return 'เสมอ'
        case 4:
          return 'Bet'
        case 5:
          return 'Cancel Bet'
        case 6:
          return 'Void'
        case 7:
          return 'Unvoid'
        default:
          return type
      }
    },
    $displayHistoryColor(type) {
       switch (type) {
        case `${0}`:
          case `${2}`:
          return 'light-success'
        case `${1}`:
          return 'light-danger'
        case `${3}`:
          return 'light-warning'
        case `${4}`:
        case `${5}`:
        case `${6}`:
        case `${7}`:
          return 'light-info'
        default:
          return 'light-primary'
      }
    },
    $displayHistoryTextColor(type) {
      switch (type) {
       case `${0}`:
         case `${2}`:
         return 'text-success'
       case `${1}`:
         return 'text-danger'
       case `${3}`:
         return 'text-warning'
       case `${4}`:
       case `${5}`:
       case `${6}`:
       case `${7}`:
         return 'text-info'
       default:
         return 'text-primary'
     }
   },
  },
})
