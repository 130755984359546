export default [
	{
		key: 'main:0',
		icon: 'StarIcon',
		path: '/',
		name: 'bills',
		route: 'bills',
		header: 'bills',
		redirect: '/billings',
		meta: { 
			authRequired: true,
			isAppMenu: true,
		},
		component: {
			render(c) {
				return c('router-view')
			},
		},
		children: [
			{
				key: 'dashboard:0',
				path: '/dashboard',
				name: 'dashboard',
				route: 'dashboard',
				icon: 'StarIcon',
				component: () => import('@/views/dashboard/analytics/Analytics.vue'),
				meta: { 
					authRequired: true,
					isAppMenu: true,
					breadcrumb: [],
				},
			},
			{
				key: 'BillingPermission:2',
				path: '/billings',
				name: 'billings',
				route: 'billings',
				icon: 'DollarSignIcon',
				component: () => import('@/views/bills/Summary.vue'),
				meta: { 
					authRequired: true,
					isAppMenu: true,
					breadcrumb: [],
				},
			},
		],
	},
  ]