import router from '../../router/index'
import UserService from '../../services/user'

const mutations = {
  LOGIN_REQUEST(state) {
    state.isLoggingIn = true
  },
  LOGIN_SUCCESS(state) {
    state.isLoggingIn = false
    state.isLoggingInError = true
  },
  LOGIN_FAILURE(state) {
    state.isLoggingIn = false
  },
  VALIDATE_USER_SUCCESS(state) {
    state.loggedIn = true
  },
  VALIDATE_USER_FAILURE(state) {
    state.loggedIn = false
  },
}
const actions = {
  async getInitialProps({ commit, dispatch }) {
    const userToken = UserService.getAccessToken()
    if (userToken) {
      await dispatch('getMe')
      await dispatch('getRequests')
      await dispatch('getBills', {
        skip: '', 
        limit: 10,
        search: ''
      })
      commit('VALIDATE_USER_SUCCESS')
    } else {
      commit('VALIDATE_USER_FAILURE')
    }
  },
  async login({ commit, dispatch }, { email, password }) {
    try {
      commit('LOGIN_REQUEST')
      const result = await UserService.login(email, password)
      const token = result?.token || result?.data?.token
      if (token) {
        UserService.saveAccessToken(token)
        commit('LOGIN_SUCCESS', token)
        await dispatch('getInitialProps')
        router.replace('/')
      }
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('LOGIN_FAILURE')
    }
  },
  async logout({ commit }) {
    localStorage.clear()
    commit('VALIDATE_USER_FAILURE')
    const { path } = router.currentRoute
    if (path !== '/login') {
      router.replace('/login')
    }
  },
}
const getters = {
  loggedIn: (state) => state.loggedIn,
}
const state = {
  loggedIn: false,
  isLoggingIn: false,
  isLoggingInError: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
