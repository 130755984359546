import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}`

export default {
  getAccounts() {
    try {
      return axios.get(`${baseURL}/accounts/agents`)
    } catch (e) {
      throw e
    }
  },
  registerLink() {
    try {
      return axios.post(`${baseURL}/accounts/registration`)
    } catch (e) {
      throw e
    }
  },
  activateCredential(data) {
    try {
      return axios.post(`${baseURL}/credentials/activate`, data)
    } catch (e) {
      throw e
    }
  },
  suspendCredential(data) {
    try {
      return axios.post(`${baseURL}/credentials/suspend`, data)
    } catch (e) {
      throw e
    }
  },
  editAgent(data) {
    try {
      return axios.patch(`${baseURL}/accounts/agents`, data)
    } catch (e) {
      throw e
    }
  },
  removeAgent(id) {
    try {
      return axios.delete(`${baseURL}/accounts/agents/${id}`)
    } catch (e) {
      throw e
    }
  },
}
