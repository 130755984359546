<template>
  <div>
    <b-form-datepicker
      v-model="value"
      placeholder="ยังไม่ได้กำหนด"
      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
      reset-button
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'DayPicker',
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
    initialValue: {
      type: [String, Date],
      default: dayjs().format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      value: null,
      dateConfig: {
        monthSelect: {
          plugins: [],
        },
      },
    }
  },
  watch: {
    value(val) {
      this.$emit('update', val)
    },
    initialValue() {
      // if (value) {
      //     this.value = dayjs(value).toDate()
      // }
    },
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue
    }
  },
}
</script>
