export default [
  {
    key: 'games:0',
    path: '/games',
    name: 'games',
    route: 'games',
    header: 'games',
    meta: {
      authRequired: true,
      isAppMenu: true,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'GamePermission:5',
        path: '/games/list',
        name: 'game_list',
        route: 'game_list',
        icon: 'TargetIcon',
        component: () => import('@/views/games/GameList.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          breadcrumb: [],
          icon: 'TargetIcon',
        },
      },
      {
        key: 'GameSetting:5',
        path: '/games/setting/:id',
        name: 'game_setting',
        route: 'game_setting',
        icon: 'TargetIcon',
        component: () => import('@/views/games/GameDetail.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          icon: 'TargetIcon',
          breadcrumb: [
            {
              name: 'game_list',
              to: '/games/list',
            },
            {
              name: 'game_setting',
              active: true,
              isUseId: true,
            },
          ],
        },
      },
      {
        key: 'HistoryLogPermission:6',
        path: '/games/history',
        name: 'game_history',
        route: 'game_history',
        icon: 'ClipboardIcon',
        component: () => import('@/views/games/History.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          breadcrumb: [],
          icon: 'ClipboardIcon',
        },
      },
      {
        key: 'gameHistory:0',
        path: '/game/history/detail/:id',
        name: 'game_history_detail',
        route: 'game_history_detail',
        icon: 'ClipboardIcon',
        component: () => import('@/views/games/HistoryDetail.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          breadcrumb: [
            {
              name: 'game_history',
              to: '/games/history',
            },
            {
              name: 'game_history_detail',
              active: true,
              isUseId: true,
            },
          ],
        },
      },
    ],
  },
]
