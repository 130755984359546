import RequestService from '../../services/requests'
import router from '../../router/index'

const mutations = {
  GET_REQUESTS_REQUEST(state) {
    state.isGettingRequests = true
  },
  GET_REQUESTS_SUCCESS(state, payload) {
    state.isGettingRequests = false
    if (payload) {
      state.requests = payload
    }
  },
  GET_REQUESTS_FAILURE(state) {
    state.isGettingRequests = false
  },
  GET_REQUEST_REQUEST(state) {
    state.isGettingRequestDetail = true
  },
  GET_REQUEST_SUCCESS(state, payload) {
    state.isGettingRequestDetail = false
    state.requestDetail = payload
  },
  GET_REQUEST_FAILURE(state) {
    state.isGettingRequestDetail = false
  },
  APPROVE_REQUEST_REQUEST(state) {
    state.isApprovingRequest = true
  },
  APPROVE_REQUEST_SUCCESS(state) {
    state.isApprovingRequest = false
  },
  APPROVE_REQUEST_FAILURE(state) {
    state.isApprovingRequest = false
  },
  REJECT_REQUEST_REQUEST(state) {
    state.isRejectingRequest = true
  },
  REJECT_REQUEST_SUCCESS(state) {
    state.isRejectingRequest = false
  },
  REJECT_REQUEST_FAILURE(state) {
    state.isRejectingRequest = false
  },

  UPDATE_REQUEST_REQUEST(state) {
    state.isUpdatingRequest = true
    state.isUpdateRequestSuccess = false
  },
  UPDATE_REQUEST_SUCCESS(state) {
    state.isUpdatingRequest = false
    state.isUpdateRequestSuccess = true
  },
  UPDATE_REQUEST_FAILURE(state) {
    state.isUpdatingRequest = false
    state.isUpdateRequestSuccess = false
  },
}
const actions = {
  async getRequests({ commit, dispatch }) {
    try {
      commit('GET_REQUESTS_REQUEST')
      const { data } = await RequestService.getRequests()
      commit('GET_REQUESTS_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_REQUESTS_FAILURE')
    }
  },
  async getRequestDetail({ commit, dispatch }, { id, agentId }) {
    try {
      commit('GET_REQUEST_REQUEST')
      if (id) {
        const response = await RequestService.getRequestById(id)
        commit('GET_REQUEST_SUCCESS', response)
      } else {
        const response = await RequestService.getRequestByAgentId(agentId)
        commit('GET_REQUEST_SUCCESS', response)
      }
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_REQUEST_FAILURE')
    }
  },
  async approveRequest({ commit, dispatch }, { id, data }) {
    try {
      commit('APPROVE_REQUEST_REQUEST')
      const response = await RequestService.approveRequest(id, data)
      commit('APPROVE_REQUEST_SUCCESS', response)
      dispatch('getRequests')
      router.replace('/requests')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('APPROVE_REQUEST_FAILURE')
    }
  },
  async rejectRequest({ commit, dispatch }, id) {
    try {
      commit('REJECT_REQUEST_REQUEST')
      await RequestService.rejectRequest(id)
      dispatch('getRequests')
      router.replace('/requests')
      commit('REJECT_REQUEST_SUCCESS')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REJECT_REQUEST_FAILURE')
    }
  },
  async updateRequest({ commit, dispatch }, data) {
    try {
      commit('UPDATE_REQUEST_REQUEST')
      const response = await RequestService.updateRequest(data)
      dispatch('app/notifySuccess')
      commit('UPDATE_REQUEST_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('UPDATE_REQUEST_FAILURE')
    }
  },
}

const getters = {
  requests: (state) => state.requests,
  requestDetail: (state) => state.requestDetail,
}
const state = {
  isGettingRequests: false,
  requests: [],

  isGettingRequestDetail: false,
  requestDetail: {
    agentId: '',
    apiKey: '',
    apiKeyStatus: '',
    callbackUrl: '',
    commissionRate: 0,
    contactEmail: '',
    contactName: '',
    environment: 0,
    id: '',
    integrate_type: 0,
    status: 0,
    whitelistIp: '',
    accountType: 0,
  },

  isApprovingRequest: false,
  isRejectingRequest: false,
  isUpdatingRequest: false,
  isUpdateRequestSuccess: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
