import PaymentService from '../../services/payments'
// import router from '../../router/index'

const mutations = {
  GET_BILLS_REQUEST(state) {
    state.isGettingBills = true
  },
  GET_BILLS_SUCCESS(state, payload) {
    state.isGettingBills = false
    if (payload) {
      state.bills = payload
    }
  },
  GET_BILLS_FAILURE(state) {
    state.isGettingBills = false
  },

  GET_INVOICES_REQUEST(state) {
      state.isGettingInvoices = true
  },
  GET_INVOICES_SUCCESS(state, payload) {
    state.isGettingInvoices = false
    if (payload) {
        state.invoices = payload
    }
  },
  GET_INVOICES_FAILURE(state) {
    state.isGettingInvoices = false
  },
  ADD_INVOICE_REQUEST(state) {
    state.isAddingInvoice = true
    state.isAddingInvoiceSuccess = false
  },
  ADD_INVOICE_SUCCESS(state) {
    state.isAddingInvoice = false
    state.isAddingInvoiceSuccess = true
  },
  ADD_INVOICE_FAILURE(state) {
    state.isAddingInvoice = false
    state.isAddingInvoiceSuccess = false
  },
  RESET_INVOICE_STATES(state) {
    state.isAddingInvoice = false
    state.isAddingInvoiceSuccess = false
  },

  SAVE_INVOICE(state, payload) {
    state.invoice = payload
  },
 
  GET_PAYMENTS_REQUEST(state) {
    state.isGettingPayments = true
  },
  GET_PAYMENTS_SUCCESS(state, payload) {
    state.isGettingPayments = false
    if (payload) {
      state.payments = payload
    }
  },
  GET_PAYMENTS_FAILURE(state) {
    state.isGettingPayments = false
  },

  GET_PAYMENT_DETAIL_REQUEST(state) {
    state.isGettingPaymentDetail = true
  },
  GET_PAYMENT_DETAIL_SUCCESS(state, payload) {
    state.isGettingPaymentDetail = false
    if (payload) {
      state.paymentDetail = payload
    }
  },
  GET_PAYMENT_DETAIL_FAILURE(state) {
    state.isGettingPaymentDetail = false
  },
  
  GET_PAYMENT_SLIP_REQUEST(state) {
    state.isGettingPaymentSlip = true
  },
  GET_PAYMENT_SLIP_SUCCESS(state, payload) {
    state.isGettingPaymentSlip = false
    state.paymentSlip = payload
  },
  GET_PAYMENT_SLIP_FAILURE(state) {
    state.isGettingPaymentSlip = false
  },

  MAKE_PAYMENT_REQUEST(state) {
    state.isMakingPayment = true
    state.isMakingPaymentSuccess = false
  },
  MAKE_PAYMENT_SUCCESS(state) {
    state.isMakingPayment = false
    state.isMakingPaymentSuccess = true
  },
  MAKE_PAYMENT_FAILURE(state) {
    state.isMakingPayment = false
    state.isMakingPaymentSuccess = false
  },
  REMOVE_PAYMENT_REQUEST(state) {
    state.isRemovingPayment = true
    state.isRemovingPaymentSuccess = false
  },
  REMOVE_PAYMENT_SUCCESS(state) {
    state.isRemovingPayment = false
    state.isRemovingPaymentSuccess = true
  },
  REMOVE_PAYMENT_FAILURE(state) {
    state.isRemovingPayment = false
    state.isRemovingPaymentSuccess = false
  },
}
const actions = {
  async getBills({ commit, dispatch }, { search = '', skip = '', limit = '' }) {
    try {
      commit('GET_BILLS_REQUEST')
      const response = await PaymentService.getBills(search, skip, limit)
      commit('GET_BILLS_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_BILLS_FAILURE')
    }
  },
  async getInvoices({ commit, dispatch }, { search = '', skip = '', limit = '', status = '' }) {
    try {
      commit('GET_INVOICES_REQUEST')
      const response = await PaymentService.getInvoices(search, skip, limit, status)
      commit('GET_INVOICES_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_INVOICES_FAILURE')
    }
  },
  saveInvoice({ commit }, invoice) {
    commit('SAVE_INVOICE', invoice)
  },
  async addInvoice({ commit, dispatch }, data) {
    try {
      commit('ADD_INVOICE_REQUEST')
      const response = await PaymentService.addInvoice(data)
      commit('ADD_INVOICE_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('ADD_INVOICE_FAILURE')
    }
  },
  resetInvoiceStates({ commit }) {
    commit('RESET_INVOICE_STATES')
  },
  async getPayments({ commit, dispatch }, { search = '', skip = '', limit = '' }) {
    try {
      commit('GET_PAYMENTS_REQUEST')
      const response = await PaymentService.getPayments(search, skip, limit)
      commit('GET_PAYMENTS_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_PAYMENTS_FAILURE')
    }
  },
  async getPaymentDetail({ commit, dispatch }, id) {
    try {
      commit('GET_PAYMENT_DETAIL_REQUEST')
      const response = await PaymentService.getPaymentById(id)
      commit('GET_PAYMENT_DETAIL_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_PAYMENT_DETAIL_FAILURE')
    }
  },
  async getPaymentSlip({ commit, dispatch }, id) {
    try {
      commit('GET_PAYMENT_SLIP_REQUEST')
      const response = await PaymentService.getPaymentSlip(id)
      console.log('getPaymentSlip', response)
      commit('GET_PAYMENT_SLIP_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_PAYMENT_SLIP_FAILURE')
    }
  },
  async makePayment({ commit, dispatch }, data) {
    try {
      commit('MAKE_PAYMENT_REQUEST')
      await PaymentService.makePayment(data)
      commit('MAKE_PAYMENT_SUCCESS')
      dispatch('app/notifySuccess')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('MAKE_PAYMENT_FAILURE')
    }
  },
  async removePayment({ commit, dispatch }, id) {
    try {
      commit('REMOVE_PAYMENT_REQUEST')
      const response = await PaymentService.removePayment(id)
      commit('REMOVE_PAYMENT_SUCCESS', response)
      dispatch('app/notifySuccess')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REMOVE_PAYMENT_FAILURE')
    }
  },
}

const getters = {
  bills: (state) => state.bills,
  invoices: (state) => state.invoices,
  payments: (state) => state.payments,
  paymentDetail: (state) => state.paymentDetail,
  paymentSlip: (state) => state.paymentSlip,
  invoice: (state) => state.invoice,
}
const state = {
  isGettingBills: false,
  bills: {
    data: [],
    totalItems: 0
  },

  isGettingInvoices: false,
  invoices: {
    data: [],
    totalItems: 0
  },
  isAddingInvoice: false,
  isAddingInvoiceSuccess: false,
  invoice: {},

  isGettingPayments: false,
  payments: {
    data: [],
    totalItems: 0
  },

  isGettingPaymentDetail: false,
  paymentDetail: {},

  isGettingPaymentSlip: false,
  paymentSlip: {},

  isMakingPayment: false,
  isMakingPaymentSuccess: false,

  isRemovingPayment: false,
  isRemovingPaymentSuccess: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
