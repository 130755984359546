import Vue from 'vue'
import dayjs from 'dayjs'
require('dayjs/locale/th')

Vue.filter('date', (value) => {
  if (value) {
    const date = dayjs(value).add(543, 'year')
    return date.locale('th').format('DD MMM YY')
  }
  return '-'
})
Vue.filter('datetime', (value) => {
  if (value) {
    const date = dayjs(value).add(543, 'year')
    return date.locale('th').format('DD MMM YY HH:mm')
  }
  return '-'
})
Vue.filter('time', (value) => {
  if (value) {
    const date = dayjs(value)
    return date.format('HH:mm')
  }
  return '-'
})
Vue.filter('money', (value) => {
  if (value) {
    const result = Number(value)
    return result.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }
  return '0'
})
Vue.filter('currency', (value) => {
  if (value) {
    const result = Number(Number(value).toFixed(2))
    return result.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }
  return '0.00'
})
