export default [
  {
    key: 'account:0',
    icon: '',
    path: '/account',
    name: 'profile',
    route: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: false,
    },
  },
]
