export default [
  {
    key: 'main:0',
    icon: 'StarIcon',
    path: '/agents',
    name: 'manage_agents',
    route: 'manage_agents',
    header: 'manage_agents',
    redirect: '/agents',
    meta: {
      authRequired: true,
      isAppMenu: true,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'IntegrationRequestPermission:3',
        path: '/requests',
        name: 'agents_requests',
        route: 'agents_requests',
        icon: 'UserPlusIcon',
        component: () => import('@/views/agents/AgentRequests.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          breadcrumb: [],
          icon: 'UserPlusIcon',
        },
      },
      {
        key: 'AgentPermission:4',
        path: '/agents/list',
        name: 'agents_list',
        route: 'agents_list',
        icon: 'UserCheckIcon',
        component: () => import('@/views/agents/AgentList.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          breadcrumb: [],
          icon: 'UserCheckIcon',
        },
      },
      {
        key: 'agentsRegister:0',
        path: '/agents/register',
        name: 'agents_register',
        route: 'agents_register',
        component: () => import('@/views/agents/AgentRegister.vue'),
        meta: {
          layout: 'full',
          authRequired: false,
          isAppMenu: false,
          breadcrumb: [],
        },
      },
    ],
  },
  {
    key: 'agentsDetail:0',
    path: '/agents/detail',
    name: 'agents_detail',
    route: 'agents_detail',
    icon: 'UserPlusIcon',
    component: () => import('@/views/agents/AgentDetail.vue'),
    meta: {
      authRequired: true,
      isAppMenu: false,
      breadcrumb: [
        {
          name: 'agents_requests',
          to: '/agents/requests',
        },
        {
          name: 'agents_detail',
          active: true,
        },
      ],
      icon: 'UserPlusIcon',
    },
  },
  {
    key: 'registerSuccess:0',
    path: '/register',
    name: 'register_success',
    route: 'register_success',
    component: () => import('@/views/agents/AgentRegisterSuccess.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
      isAppMenu: false,
      breadcrumb: [],
    },
  },
]
