<template>
  <img
    v-if="bankAbbrev"
    :src="require(`@/assets/images/banks/${bankAbbrev}.svg`)"
    :alt="bankAbbrev"
    :style="`background-color: ${bankColor}`"
    class="bank"
    width="25"
  >
</template>

<script>
import { BANK_ICONS, banks } from '@/libs/constants'

export default {
  name: 'BankIcon',
  props: {
    bankCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      banks: banks,
      bank: BANK_ICONS,
    }
  },
  computed: {
    bankAbbrev() {
      const found = this.banks.find((prop) => prop.bank_code === this.bankCode)
      return found?.bank_abbrev.toLowerCase()
    },
    bankColor() {
      return BANK_ICONS[this.bankAbbrev]?.color || '#fff'
    },
  },
}
</script>

<style lang="scss" scoped>
.bank {
  padding: 2px;
  border-radius: 7px;
}
</style>
