import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}`

export default {
  getHistory() {
    try {
      return axios.get(`${baseURL}/history`)
    } catch (e) {
      throw e
    }
  },
  getHistoryDetail(id) {
    try {
      return axios.get(`${baseURL}/history/${id}`)
    } catch (e) {
      throw e
    }
  },
  getGameList() {
    try {
      return axios.get(`${baseURL}/games`)
    } catch (e) {
      throw e
    }
  },
  getGameDetail(id) {
    try {
      return axios.get(`${baseURL}/games/${id}`)
    } catch (e) {
      throw e
    }
  },
}
