import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/integration-requests`

export default {
  getRequests() {
    try {
      return axios.get(`${baseURL}`)
    } catch (e) {
      throw e
    }
  },
  getRequestById(id) {
    try {
      return axios.get(`${baseURL}/detail?id=${id}`)
    } catch (e) {
      throw e
    }
  },
  getRequestByAgentId(agentId) {
    try {
      return axios.get(`${baseURL}/detail?agent_id=${agentId}`)
    } catch (e) {
      throw e
    }
  },
  approveRequest(id, params) {
    try {
      return axios.patch(`${baseURL}/${id}/approve`, params)
    } catch (e) {
      throw e
    }
  },
  rejectRequest(id) {
    try {
      return axios.patch(`${baseURL}/${id}/reject`)
    } catch (e) {
      throw e
    }
  },
  updateRequest(data) {
    try {
      return axios.patch(`${baseURL}`, data)
    } catch (e) {
      throw e
    }
  },
}
