import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}`

export const isUserLoggedIn = () => {
  return localStorage.getItem('ADMIN_TOKEN')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export default {
  saveAccessToken(token) {
    localStorage.setItem('ADMIN_TOKEN', token)
  },
  getAccessToken() {
    return localStorage.getItem('ADMIN_TOKEN')
  },
  getMe() {
    try {
      return axios.get(`${baseURL}/accounts/me`)
    } catch (e) {
      throw e
    }
  },
  login(email, password) {
    try {
      return axios.post(`${baseURL}/accounts/sign-in`, { email, password })
    } catch (e) {
      throw e
    }
  },
  registerLink() {
    try {
      return axios.post(`${baseURL}/accounts/registration`)
    } catch (e) {
      throw e
    }
  },
}
