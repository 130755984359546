import AssistantService from '../../services/assistants'
import router from '../../router/index'

const mutations = {
  GET_PERMISSIONS_REQUEST(state) {
    state.isGettingPermissions = true
  },
  GET_PERMISSIONS_SUCCESS(state, payload) {
    state.isGettingPermissions = false
    state.permissions = payload
  },
  GET_PERMISSIONS_FAILURE(state) {
    state.isGettingPermissions = false
  },

  GET_ASSISTANTS_REQUEST(state) {
    state.isGettingAssistants = true
  },
  GET_ASSISTANTS_SUCCESS(state, payload) {
    state.isGettingAssistants = false
    state.assistants = payload
  },
  GET_ASSISTANTS_FAILURE(state) {
    state.isGettingAssistants = false
  },

  GET_ASSISTANT_REQUEST(state) {
    state.isGettingAssistantDetail = true
  },
  GET_ASSISTANT_SUCCESS(state, payload) {
    state.isGettingAssistantDetail = false
    state.assistantDetail = payload
  },
  GET_ASSISTANT_FAILURE(state) {
    state.isGettingAssistantDetail = false
  },

  CREATE_ASSISTANT_REQUEST(state) {
    state.isCreatingAssistant = true
  },
  CREATE_ASSISTANT_SUCCESS(state) {
    state.isCreatingAssistant = false
  },
  CREATE_ASSISTANT_FAILURE(state) {
    state.isCreatingAssistant = false
  },

  UPDATE_ASSISTANT_REQUEST(state) {
    state.isUpdatingAssistant = true
  },
  UPDATE_ASSISTANT_SUCCESS(state) {
    state.isUpdatingAssistant = false
  },
  UPDATE_ASSISTANT_FAILURE(state) {
    state.isUpdatingAssistant = false
  },

  REMOVE_ASSISTANT_REQUEST(state) {
    state.isRemovingAssistant = true
  },
  REMOVE_ASSISTANT_SUCCESS(state) {
    state.isRemovingAssistant = false
  },
  REMOVE_ASSISTANT_FAILURE(state) {
    state.isRemovingAssistant = false
  },
}
const actions = {
  async getPermissions({ commit, dispatch }) {
    try {
      commit('GET_PERMISSIONS_REQUEST')
      const { data } = await AssistantService.getPermissions()
      commit('GET_PERMISSIONS_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_PERMISSIONS_FAILURE')
    }
  },
  async getAssistants({ commit, dispatch }) {
    try {
      commit('GET_ASSISTANTS_REQUEST')
      const { data } = await AssistantService.getAssistants()
      commit('GET_ASSISTANTS_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ASSISTANTS_FAILURE')
    }
  },
  async getAssistant({ commit, dispatch }, id) {
    try {
      commit('GET_ASSISTANT_REQUEST')
      const response = await AssistantService.getAssistantById(id)
      commit('GET_ASSISTANT_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ASSISTANT_FAILURE')
    }
  },
  async createAssistant({ commit, dispatch }, data) {
    try {
      commit('CREATE_ASSISTANT_REQUEST')
      const response = await AssistantService.createAssistant(data)
      commit('CREATE_ASSISTANT_SUCCESS', response)
      dispatch('app/notifySuccess')
      router.replace('/assistants')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('CREATE_ASSISTANT_FAILURE')
    }
  },
  async updateAssistant({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_ASSISTANT_REQUEST')
      const response = await AssistantService.updateAssistant({...data, id})
      commit('UPDATE_ASSISTANT_SUCCESS', response)
      dispatch('app/notifySuccess', 'แก้ไขข้อมูลสำเร็จ')
      dispatch('getAssistant', id)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('UPDATE_ASSISTANT_FAILURE')
    }
  },
  async removeAssistant({ commit, dispatch }, id) {
    try {
      commit('REMOVE_ASSISTANT_REQUEST')
      const response = await AssistantService.removeAssistant(id)
      commit('REMOVE_ASSISTANT_SUCCESS', response)
      dispatch('app/notifySuccess')
      router.replace('/assistants')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REMOVE_ASSISTANT_FAILURE')
    }
  }
}

const getters = {
  permissions: (state) => state.permissions,
  assistants: (state) => state.assistants,
  assistantDetail: (state) => state.assistantDetail,
}
const state = {
  isGettingPermissions: false,
  permissions: [],

  isGettingAssistants: false,
  assistants: [],

  isGettingAssistantDetail: false,
  assistantDetail: '',

  isCreatingAssistant: false,
  isUpdatingAssistant: false,
  isRemovingAssistant: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
